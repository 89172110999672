import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Grid,
  Link,
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  extendTheme,
  Image,
  SimpleGrid,
  Center,
  Icon
} from '@chakra-ui/react';
import { MdSecurity, MdMemory, MdPublic } from 'react-icons/md';
import { useForm, ValidationError } from '@formspree/react';

const theme = extendTheme({
  fonts: {
    heading: "'Montserrat', sans-serif",
    body: "'Montserrat', sans-serif",
  },
});

const NavLink = ({ href, children }) => (
  <Link href={href} color="gray.300" _hover={{ color: "white" }}>
    {children}
  </Link>
);

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mpwajkge");
  const toast = useToast();

  React.useEffect(() => {
    if (state.succeeded) {
      toast({
        title: "Message sent.",
        description: "Thanks for reaching out! We'll get back to you soon.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [state.succeeded, toast]);

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel htmlFor="email" color="gray.700">Email Address</FormLabel>
          <Input 
            id="email" 
            type="email" 
            name="email" 
            color="black"
            _placeholder={{ color: 'gray.500' }}
            bg="white"
            borderColor="gray.300"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="message" color="gray.700">Message</FormLabel>
          <Textarea 
            id="message" 
            name="message" 
            color="black"
            _placeholder={{ color: 'gray.500' }}
            bg="white"
            borderColor="gray.300"
          />
          <ValidationError prefix="Message" field="message" errors={state.errors} />
        </FormControl>
        <Button type="submit" colorScheme="blue" isLoading={state.submitting}>
          Send Message
        </Button>
      </VStack>
    </Box>
  );
};

const Section = ({ id, title, children, bg }) => {
  const titleColor = bg === "black" ? "white" : "black";
  return (
    <Box id={id} py={20} bg={bg}>
      <Container maxW="container.xl">
        <Heading as="h2" size="xl" mb={8} color={titleColor}>
          {title}
        </Heading>
        {children}
      </Container>
    </Box>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <Box bg="gray.800" p={6} borderRadius="lg" boxShadow="lg">
    <Icon as={icon} w={12} h={12} color="white" mb={4} />
    <Heading as="h3" size="md" mb={2} color="white">
      {title}
    </Heading>
    <Text color="gray.300">{description}</Text>
  </Box>
);

const FontLoader = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return null;
};

const NewsClip = ({ logo, content }) => (
  <VStack align="center" spacing={4}>
    <Center w="100%" h="100px">
      <Image 
        src={logo} 
        alt="Company Logo" 
        maxH="100px" 
        maxW="100%" 
        objectFit="contain" 
      />
    </Center>
    <Text fontSize="sm" color="gray.700" textAlign="center">
      {content}
    </Text>
  </VStack>
);

const NewsSection = () => (
  <Section id="news" title="Latest News" bg="gray.100">
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
      <NewsClip
        logo="/images/pear.png"
        content="Pear VC invites Argus Systems to join its Founders-In-Residence Program for Summer 2024."
      />
      <NewsClip
        logo="/images/mbafund.png"
        content="Argus Systems wins the Harvard MBA Fund startup competition and a $25,000 investment prize."
      />
      <NewsClip
        logo="/images/hbs.png"
        content="Argus Systems is selected for the HBS's Rock Entrepreneurship Fellowship and will receive experimentation funds for Summer 2024."
      />
    </SimpleGrid>
  </Section>
);

export default function ArgusSystems() {
  return (
    <ChakraProvider theme={theme}>
      <FontLoader />
      <Box bg="gray.900" color="white">
        <Box
          as="header"
          py={4}
          position="absolute"
          top={0}
          left={0}
          right={0}
          zIndex={10}
        >
          <Container maxW="container.xl">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading as="h1" size="lg" color="white" letterSpacing="0.1em">
                ARGUS SYSTEMS
              </Heading>
              <Flex alignItems="center" gap={8}>
                <Button 
                  as="a" 
                  href="#contact" 
                  bg="white" 
                  color="black" 
                  _hover={{ bg: "gray.200" }} 
                  borderRadius="full" 
                  px={6}
                >
                  Contact
                </Button>
              </Flex>
            </Flex>
          </Container>
        </Box>

        <Box as="main">
          <Box id="home" height="100vh" position="relative" overflow="hidden">
            <Box
              as="video"
              autoPlay
              loop
              muted
              playsInline
              position="absolute"
              width="100%"
              height="100%"
              objectFit="cover"
              src="/videos/combined_20s.mp4"
            />
            <Flex
              position="absolute"
              inset={0}
              bg="blackAlpha.500"
              alignItems="flex-end"
              justifyContent="flex-start"
            >
              <Container maxW="container.xl" mb={16}>
                <VStack align="flex-start" spacing={2} p={8} maxW="container.lg">
                  <Heading as="h3" size="xl" fontWeight="medium" color="white">
                    Argus Systems
                  </Heading>
                  <Heading as="h1" size="4xl" lineHeight={1.2} color="white">
                    Building The World's Best Geospatial AI Agent
                  </Heading>
                </VStack>
              </Container>
            </Flex>
          </Box>

          <Section id="platform" bg="white">
            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center" spacing={8}>
              <Box flex={1} pr={{ lg: 12 }} mb={{ base: 8, lg: 0 }}>
                <VStack spacing={6} align="flex-start">
                  <VStack spacing={2} align="flex-start">
                    <Text
                      fontSize="xl"
                      fontWeight="medium"
                      color="gray.600"
                      textTransform="uppercase"
                      letterSpacing="wide"
                    >
                      See All. Gather Evidence.
                    </Text>
                    <Heading
                      as="h2"
                      size="2xl"
                      color="black"
                      fontWeight="bold"
                      lineHeight="1.2"
                    >
                      You Make the Call.
                    </Heading>
                  </VStack>
                  <Text fontSize="lg" color="black">
                    Argus combines state-of-the-art object detection and synthesizes data from a wide array of sources—including news, social media, and your proprietary data— to get you the answers you need in seconds.
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <Image
                  src="/images/demo.png"
                  alt="Argus Systems Platform Demo"
                  borderRadius="lg"
                  objectFit="cover"
                  w="100%"
                  h="auto"
                />
              </Box>
            </Flex>
          </Section>

          <Section id="technology" title="Technology" color="white" bg="black" >
            <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={8}>
              <FeatureCard
                icon={MdMemory}
                title="Multi-Modal AI Fusion"
                description="We seamlessly integrate state-of-the-art computer vision 
                with advanced language models, delivering unprecedented depth in geospatial 
                intelligence. This fusion enables our AI to generate holistic insights so 
                you can make the most informed decision."
              />
              <FeatureCard
                icon={MdPublic}
                title="Comprehensive Data Integration"
                description="We synthesize a vast array of external 
                sources—including high-resolution satellite imagery, real-time news feeds, 
                and dynamic social media data—with your proprietary intelligence. "
              />
              <FeatureCard
                icon={MdSecurity}
                title="Verifiable AI Insights"
                description="In critical decision-making scenarios, our explainable AI mitigates the risk of hallucinations 
                by verifying outputs with trustworthy sources, ensuring that you have evidence-based intelligence to 
                confidently make the final call."
              />
            </Grid>
          </Section>
          <NewsSection />
          <Section id="contact" title="Want to learn more?" bg="white">
            <Text fontSize="lg" mb={6} color="black">
              Reach out to the Argus Systems team today.
            </Text>
            <ContactForm />
          </Section>
        </Box>

        <Box as="footer" bg="black" py={8} color="white">
          <Container maxW="container.xl" textAlign="center">
            <Text>&copy; 2024 Argus Systems. All rights reserved.</Text>
          </Container>
        </Box>
      </Box>
    </ChakraProvider>
  );
}